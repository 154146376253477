<template>
  <el-dialog :visible.sync="visible" :title="dataForm.id ? '修改' : '新增'">
    <el-form label-width="100px">
      <el-form-item label="类型">
        <el-radio v-model="dataForm.type" :label="0">菜单</el-radio>
        <el-radio v-model="dataForm.type" :label="1">按钮</el-radio>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="dataForm.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="上级菜单">
        <el-popover
          v-model="menuListVisible"
          ref="menuListPopover"
          trigger="click"
          placement="bottom-start"
        >
          <el-tree
            :data="menuList"
            :props="{ label: 'name', children: 'children' }"
            node-key="id"
            ref="menuListTreeDom"
            :highlight-current="true"
            :expand-on-click-node="false"
            accordion
            @current-change="menuListTreeCurrentChangeHandler"
          ></el-tree>
        </el-popover>

        <el-input
          v-model="parentName"
          v-popover:menuListPopover
          :readonly="true"
          placeholder="一级菜单"
        >
          <i
            v-if="dataForm.parentId && dataForm.parentId != 0"
            slot="suffix"
            @click.stop="deptListTreeSetDefaultHandle()"
            class="el-icon-circle-close el-input__icon"
          ></i>
        </el-input>
      </el-form-item>
      <el-form-item label="路由">
        <el-input
          v-model="dataForm.url"
          placeholder="请输入路由"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number
          v-model="dataForm.sort"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="图标">
        <el-popover
          v-model="iconListVisible"
          ref="iconListPopover"
          placement="bottom-start"
          trigger="click"
          popper-class="icon-popper"
        >
          <ul class="icon-list">
            <li class="icon-item">
              <el-button
                v-for="(item, index) in iconList"
                :key="index"
                @click="iconListCurrentChangeHandle(item)"
                :class="{ 'is-active': dataForm.icon === item }"
              >
                <svg class="icon-svg" aria-hidden="true">
                  <use :xlink:href="`#${item}`"></use>
                </svg>
              </el-button>
            </li>
          </ul>
        </el-popover>
        <el-input
          v-model="dataForm.img"
          v-popover:iconListPopover
          :readonly="true"
          placeholder="请选择图标"
          ><i
            v-if="dataForm.img"
            slot="suffix"
            @click.stop="clearIconHandler()"
            class="el-icon-circle-close el-input__icon"
          ></i
        ></el-input>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitHandler">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getIconList } from "@/utils/index.js";

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: "",
        parentId: "",
        name: "",
        url: "",
        type: 0,
        sort: "",
        img: "",
      },
      parentName: "",
      menuListVisible: false,
      iconListVisible: false,
      iconList: [],
      menuList: [],
    };
  },
  methods: {
    init(id, menuList) {
      this.dataInit();

      if (id) {
        this.dataForm.id = id;
        this.getMenuInfo();
      }
      this.menuList = menuList || [];
      this.visible = true;
      console.log(this.dataForm.parentId);
    },
    // 初始化数据
    dataInit() {
      // 获取图标列表
      this.iconList = getIconList();

      this.selectMenu = [];

      this.dataForm = {
        id: "",
        parentId: "",
        name: "",
        url: "",
        type: 0,
        sort: "",
        img: "",
      };
      this.parentName = "";
    },
    // 获取菜单信息
    getMenuInfo() {
      this.$http.menu.info({ id: this.dataForm.id }).then((res) => {
        this.dataForm = res.data;
        this.$refs["menuListTreeDom"].setCurrentKey(res.data.parentId);

        this.menuList.forEach((ele) => {
          if (ele.id == res.data.parentId) {
            this.parentName = ele.name;
          }
          if (ele.children && ele.children.length > 0) {
            ele.children.forEach((child) => {
              if (child.id == res.data.parentId) {
                this.parentName = child.name;
              }
            });
          }
        });
      });
    },
    // 当前选中父节点
    menuListTreeCurrentChangeHandler(item) {
      this.dataForm.parentId = item.id;
      this.parentName = item.name;
      this.menuListVisible = false;
    },
    // 提交表单（新增或更新）
    submitHandler() {
      // 修改
      if (this.dataForm.id) {
        this.updateMenuHandler();
      }
      // 新增
      else {
        this.addMenuHandler();
      }
    },
    // 新增菜单
    addMenuHandler() {
      this.$http.menu.save(this.dataForm).then((res) => {
        this.$message.success({
          message: res.msg,
          type: "success",
          duration: 500,
          onClose: () => {
            this.visible = false;
            this.$nextTick(() => {
              this.$emit("refreshDataList");
            });
          },
        });
      });
    },
    // 修改菜单
    updateMenuHandler() {
      this.$http.menu.update(this.dataForm).then((res) => {
        this.$message.success({
          message: res.msg,
          type: "success",
          duration: 500,
          onClose: () => {
            this.visible = false;
            this.$nextTick(() => {
              this.$emit("refreshDataList");
            });
          },
        });
      });
    },
    // 选择图标
    iconListCurrentChangeHandle(icon) {
      this.dataForm.img = icon;
      this.iconListVisible = false;
    },

    // 删除父级菜单
    deptListTreeSetDefaultHandle() {
      this.dataForm.parentId = "";
      this.parentName = "";
    },
    // 清除选中图标
    clearIconHandler() {
      this.dataForm.img = "";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .el-icon-circle-close {
    cursor: pointer;
  }
}
.icon-list {
  width: 460px;
  max-height: 260px;
  overflow-x: hidden;
  overflow-y: auto;

  .icon-item {
    width: 460px;
    padding: 10px;

    :deep() {
      .el-button {
        margin: 8px 0 0 8px;

        span {
          width: 18px;
          height: 18px;

          .icon-svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}
</style>