<template>
  <div class="mod-menu">
    <div class="mod-header">
      <el-button type="primary" @click="openMenuAddOrUpdComponent()"
        >新增</el-button
      >
    </div>

    <el-table
      :data="menuList"
      row-key="id"
      :tree-props="{ children: 'children' }"
      border
    >
      <el-table-column
        label="名称"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column label="图标" prop="img" align="center">
        <template slot-scope="scope">
          <div class="svg-box">
            <svg class="icon-svg" aria-hidden="true">
              <use :xlink:href="`#${scope.row.img}`"></use>
            </svg>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="类型" prop="type" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 0" size="medium">菜单</el-tag>
          <el-tag v-else type="info" size="medium">按钮</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="排序"
        prop="sort"
        align="center"
      ></el-table-column>
      <el-table-column label="路由" prop="url" align="center"></el-table-column>

      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="openMenuAddOrUpdComponent(scope.row.id)"
            >修改
          </el-button>
          <el-button
            type="danger"
            plain
            size="mini"
            @click="deleteMenuHandler(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <menu-add-or-update
      v-if="addOrUpdate_visible"
      ref="menuAddOrUpdDom"
      @refreshDataList="getMenuList"
    ></menu-add-or-update>
  </div>
</template>

<script>
import MenuAddOrUpdate from "./menu-add-or-update.vue";

export default {
  components: {
    MenuAddOrUpdate,
  },
  data() {
    return {
      addOrUpdate_visible: false,
      menuList: [],
    };
  },
  created() {
    this.getMenuList();
  },
  methods: {
    // 打开新增或修改菜单组件
    openMenuAddOrUpdComponent(id) {
      this.addOrUpdate_visible = true;
      this.$nextTick(() => {
        this.$refs.menuAddOrUpdDom.init(id, this.menuList);
      });
    },
    // 获取菜单列表
    getMenuList() {
      this.$http.role.power().then((res) => {
        if (!res.data) return this.$message.error("返回数据错误，请稍后重试");

        this.menuList = res.data || [];
      });
    },
    // 删除菜单列表
    deleteMenuHandler(id) {
      this.$confirm("确认删除该菜单", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          this.$http.menu.delete({ id }).then((res) => {
            this.$message.success("删除成功");
            this.getMenuList();
          });
        })
        .catch(() => {
          // this.$message.info("已取消删除");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mod-header {
  margin-bottom: 20px;
}

.svg-box {
  .icon-svg {
    width: 1.3rem;
    height: 1.3rem;
  }
}
</style>